import { Box, Button, Stack } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./select-skills-level.css";
import SkillLevel from "./skill-level/skill-level";

export const SelectSkillsLevel = (props: any) => {
	const navigate = useNavigate();

	const updateSkill = (value: string, index: number) => {
		props.updateSkill(value, index);
	};

	const removeSkill = (index: number) => {
		props.removeSkill(index);
	};

	const isValid = useMemo(() => {
		return props.accountSkills?.every(
			(s: any) => Boolean(s.proficiency.length)
		)
	}, [props.accountSkills]);

	const profileRedirect = () => {
		navigate("/");
	};

	return (
		<Box className={props.className}>
			<Stack direction="column" spacing={4} justifyContent="center" alignItems="center">
				{props.mode == "edit" ? (
					<>
						<h3>Edit skills</h3>
						<p>
							Make changes to any of the skills or remove them if they aren’t relevant.
						</p>
					</>
				) : (
					<>
						<h3>Select your level</h3>
						<p>Tell us how good you are at each of those skills</p>
					</> 
				)}

				<div className="skills-levels-container">
					<div className="proficiency-row">
						<label className="skill-name">&nbsp;</label>
						<div className="proficiency-values">
							<label className="proficiency-value">Basic</label>
							<label className="proficiency-value">Intermediate</label>
							<label className="proficiency-value">Proficient</label>
							<label className="proficiency-value">Expert</label>
						</div>
					</div>
					{props.accountSkills?.map((s: any, index: number) => {
						return (
							<SkillLevel
								skill={s}
								index={index}
								updateSkill={updateSkill}
								removeSkill={removeSkill}
								mode={props.mode}
								key={index}>
								{" "}
							</SkillLevel>
						);
					})}
				</div>

				<Button
					variant="contained"
					color="secondary"
					disabled={!isValid}
					onClick={props.saveSkills}>
					Continue
				</Button>
				{props.mode == "edit" &&
					<Button
						variant="outlined"
						color="primary"
						disabled={!isValid}
						onClick={() => profileRedirect()}>
						Cancel
					</Button>
				}
			</Stack>
		</Box>

	);
};
