import Alerts from "components/alerts/alerts";
import ProfileInfo from "components/profile/profile-info/profile-info";
import ProfileSkills from "components/profile/profile-skills/profile-skills";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useProfile } from "hooks/useProfile";
import LinearProgress from "@mui/material/LinearProgress";
import { useAuthentication } from "hooks/auth/useAuthentication";

type Alert = {
	severity: string;
	message: string;
	open: boolean;
};

type LocationState = {
	alerts?: Array<Alert>;
	reason?: string;
};

const ProfilePage = () => {
	const { userID } = useParams();
	const { id } = useAuthentication();
	const isLoggedInProfile = id === userID;

	const [alerts, setAlerts] = useState<Alert[]>([]);

	const locState = useLocation()?.state as LocationState;
	const locAlerts = locState?.alerts?.length ? locState?.alerts : null;

	const { loading, profile } = useProfile(userID);

	useEffect(()=>{
		const stateAlerts: Alert[] | undefined = locAlerts?.map((alert) => {
			return { ...alert, open: true };
		}) || [];
		setAlerts(stateAlerts);
	}, [locAlerts])

	const closeAlert = (index: number) => {
		setAlerts((currentAlerts) => {
			const newAlerts = [...currentAlerts];
			newAlerts[index].open = false;
			return newAlerts;
		});
	};

	return (
		<>
			{loading &&
				<LinearProgress color="secondary"/>
			}
			{!loading && profile && profile?.skills?.length > 0 && (
				<>
					<Alerts alerts={alerts} closeAlert={closeAlert} />
					<ProfileInfo profile={profile} />
					<ProfileSkills skills={profile?.skills} isLoggedInProfile={isLoggedInProfile} />
				</>
			)}
		</>
	);
};

export default ProfilePage;
