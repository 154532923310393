const aadAuthScope = process.env.REACT_APP_AUTH_SCOPE || ".default";
const aadClientId = process.env.REACT_APP_CLIENT_ID || "client-id";
const aadTenantId = process.env.REACT_APP_TENANT || "tenant-id";
const aadRedirectUri = process.env.REACT_APP_REDIRECT_URI || "http://localhost:3000";

export const msalConfig = {
	auth: {
		clientId: aadClientId,
		authority: `https://login.microsoftonline.com/${aadTenantId}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
		redirectUri: aadRedirectUri,
	},
	cache: {
		cacheLocation: "localStorage", // This configures where your cache will be stored
	},
};

export const loginRequest = {
	scopes: [aadAuthScope]
};
