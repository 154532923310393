import BrowseSearch from "components/browse/browse-search/browse-search";
import BrowseResults from "components/browse/browse-results/browse-results";
import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import useAuthenticatedFetch from "hooks/auth/useAuthenticatedFetch";
import { OptionType } from "types/option-type";

const BrowsePage = () => {
	const [allUsers, setAllUsers] = useState<any>();
	const [selectedSkill, setSelectedSkill] = useState<any>();
	const [selectedBuildCentre, setSelectedBuildCentre] = useState<string>("");
	const [selectedCapability, setSelectedCapability] = useState<string>("");
	const [selectedJobTitle, setSelectedJobTitle] = useState<string>("");
	const [jobTitleFieldValue, setJobTitleFieldValue] = useState<OptionType>();
	const [buildCentreFieldValue, setBuildCentreFieldValue] =
		useState<OptionType>();
	const [capabilityFieldValue, setCapabilityFieldValue] = useState<OptionType>();

	const authenticatedFetch = useAuthenticatedFetch();

	const getAllUsers = async () => {
		const url = `${process.env.REACT_APP_PROFILE_API_URL}`;
		const response = await authenticatedFetch(url);
		setAllUsers(response);
	};

	const returnSelectedSkill = (skill: any) => {
		setSelectedSkill(skill);
	};

	useEffect(() => {
		if (selectedSkill) {
			getAllUsers();
		}
	}, [selectedSkill]);

	// const onProficiencyChange = (proficiency: OptionType) => {
	// 	setSelectedProficiency(proficiency.value);
	// 	setProficiencyFieldValue(proficiency);
	// };

	const onJobTitleChange = (title: OptionType) => {
		setSelectedJobTitle(title.value);
		setJobTitleFieldValue(title);
	};

	const onBuildCentreChange = (buildCentre: OptionType) => {
		setSelectedBuildCentre(buildCentre.value);
		setBuildCentreFieldValue(buildCentre);
	};

	const onCapabilityChange = (capability: OptionType) => {
		setSelectedCapability(capability.value);
		setCapabilityFieldValue(capability);
	};

	const resetFilters = () => {
		// onProficiencyChange({ value: "", label: "All" });
		onJobTitleChange({ value: "", label: "All" });
		onBuildCentreChange({ value: "", label: "All" });
		onCapabilityChange({ value: "", label: "All" });
	};

	return (
		<Container sx={{ width: 1200 }}>
			<BrowseSearch
				returnSelectedSkill={returnSelectedSkill}
				onJobTitleChange={onJobTitleChange}
				onBuildCentreChange={onBuildCentreChange}
				onCapabilityChange={onCapabilityChange}
				jobTitleFieldValue={jobTitleFieldValue}
				buildCentreFieldValue={buildCentreFieldValue}
				capabilityFieldValue={capabilityFieldValue}
			/>
			<BrowseResults
				allUsers={allUsers}
				selectedSkill={selectedSkill}
				selectedJobTitle={selectedJobTitle}
				selectedBuildCentre={selectedBuildCentre}
				selectedCapability={selectedCapability}
				resetFilters={resetFilters}
			/>
		</Container>
	);
};

export default BrowsePage;
