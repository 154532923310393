import { useCallback } from "react";
import { useAuthentication } from "./useAuthentication";

type HttpMethod = "GET" | "PUT" | "POST" | "DELETE";

export const useAuthenticatedFetch = () => {
	const { getAccessToken } = useAuthentication();

	const authenticatedFetch = useCallback(async (
		url: string,
		method: HttpMethod = "GET",
		data: any = null,
		isImage = false
	) => {
		try {
			const accessToken = await getAccessToken();

			const options = {
				method: method,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
			} as RequestInit;
			
			if (method === "POST" || method === "PUT") {
				options.body = JSON.stringify(data);
			}


			const response = await fetch(url, options);
			if (response.ok) {
				if (method === "DELETE") {
					return null;
				}

				const object = isImage ? await response.blob() : await response.json();
				return object;
			}
		} catch (e) {
			console.error("authenticatedFetch failed", e);
		}
		return null;


	}, [getAccessToken]);

	return authenticatedFetch;
};

export default useAuthenticatedFetch;