/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from "react";
import User from "types/user";
import { useNavigate } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Grid,
} from "@mui/material";
import "./browse-results.css";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { CSVLink } from "react-csv";
import NoResultsImage from "assets/images/no_results.svg";

// BEA 71, Advanced search - results will need to be updated into an array
const BrowseResults = ({
	allUsers,
	selectedSkill,
	selectedJobTitle,
	selectedBuildCentre,
	selectedCapability,
	resetFilters,
}: {
	allUsers: Array<User>;
	selectedSkill: string;
	selectedJobTitle: string;
	selectedBuildCentre: string;
	selectedCapability: string;
	resetFilters: () => void;
}) => {
	const navigate = useNavigate();

	const [allUsersWithSelectedSkills, setAllUsersWithSelectedSkills] =
		useState<Array<User>>();
	const [usersToDisplay, setUsersToDisplay] = useState<Array<User>>();
	const [numOfUsersToDisplay, setNumOfUsersToDisplay] = useState<number>(0);
	const [hideShowMoreButton, setHideShowMoreButton] = useState<boolean>(true);
	const [csvData, setCsvData] = useState<string[][]>([]);
	const [hideExportButton, setHideExportButton] = useState<boolean>(true);

	useEffect(() => {
		if (!allUsers) return;

		const allUsersValues = Object.values(allUsers);
		const filteredUsers = allUsersValues.filter((user) => {
			return user.skills.some((skill) => {
				return skill.name === selectedSkill;
			});
		});

		setAllUsersWithSelectedSkills(filteredUsers);
		setUsersToDisplay(filteredUsers);
		setNumOfUsersToDisplay(10);

		usersToDisplay &&
			usersToDisplay.length > 10 &&
			setHideShowMoreButton(false);
	}, [allUsers, selectedSkill]);

	const filterResultsByJobTitle = (filteredUsers: User[]) => {
		if (!selectedJobTitle || selectedJobTitle === "All")
			return filteredUsers;

		const jobTitleFilteredUsers = filteredUsers.filter(
			(user) => user.title === selectedJobTitle
		);
		return jobTitleFilteredUsers;
	};

	const filterResultsByBuildCentre = (filteredUsers: User[]) => {
		if (!selectedBuildCentre || selectedBuildCentre === "All") {
			return filteredUsers;
		}
		const buildCentreFilteredUsers = filteredUsers.filter(
			(user) => user.build_centre === selectedBuildCentre
		);

		return buildCentreFilteredUsers;
	};

	const filterResultsByCapability = (filteredUsers: User[]) => {
		if (!selectedCapability || selectedCapability === "All") {
			return filteredUsers;
		}

		const capabilityFilteredUsers = filteredUsers.filter(
			(user) => user.capability === selectedCapability
		);

		return capabilityFilteredUsers;
	};

	useEffect(() => {
		if (!allUsersWithSelectedSkills) return;
		let filteredUsers = allUsersWithSelectedSkills;
		filteredUsers = filterResultsByJobTitle(filteredUsers);
		filteredUsers = filterResultsByBuildCentre(filteredUsers);
		console.log(filteredUsers);
		filteredUsers = filterResultsByCapability(filteredUsers);

		setUsersToDisplay([...filteredUsers]);
	}, [selectedJobTitle, selectedBuildCentre, selectedCapability]);

	const showMoreButton = () => {
		if (usersToDisplay && usersToDisplay.length > 10) {
			setNumOfUsersToDisplay(numOfUsersToDisplay + 10);
		}
	};

	useEffect(() => {
		usersToDisplay &&
			usersToDisplay.length > 10 &&
			setHideShowMoreButton(false);
		usersToDisplay &&
			usersToDisplay.length < numOfUsersToDisplay &&
			setHideShowMoreButton(true);
	}, [usersToDisplay, numOfUsersToDisplay]);

	useEffect(() => {
		usersToDisplay &&
			usersToDisplay.length === 0 &&
			setHideExportButton(true);
		usersToDisplay &&
			usersToDisplay.length > 0 &&
			setHideExportButton(false);
	}, [usersToDisplay]);

	useEffect(() => {
		const data: string[][] = [
			["Name", "Build_Centre", "Capability", "Title", "Skills"],
		];

		usersToDisplay?.map((user: any) => {
			return user.skills.forEach((skill: any) => {
				if (skill.name === selectedSkill) {
					data.push([
						user.name,
						user.build_centre,
						user.capability,
						user.title,
						`${skill.name} (${skill.proficiency})`,
					]);
				}
			});
		});
		setCsvData(data);
	}, [usersToDisplay]);

	const navigateToUserProfileOnClick = useCallback(
		(id: string) => () => {
			navigate(`/profiles/${id}`);
		},
		[navigate]
	);

	return (
		<div className="browse-results-container">
			<h3 className="results-header">Results</h3>
			<Grid className="browse-header">
				<div>
					{usersToDisplay &&
						(usersToDisplay.length === 1
							? "There is 1 builder that matches your search criteria."
							: `There are ${usersToDisplay.length} builders that match your search criteria.`)}
				</div>
				<div>
					{!hideExportButton && (
						<CSVLink
							data={csvData}
							filename={`_beacon-Search-${selectedSkill}`}
						>
							<button className="white-button">
								Export results
							</button>
						</CSVLink>
					)}
				</div>
			</Grid>
			<TableContainer component={Paper} className="table-container">
				<Table
					sx={{ minWidth: 650, border: "1px" }}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableRow
							sx={{
								"&:first-child td, &:first-child th": {
									border: 1,
									borderColor: "#E5E5E5",
								},
							}}
						>
							<TableCell>
								Name
								<UnfoldMoreIcon style={{ float: "right" }} />
							</TableCell>
							<TableCell>
								Build Centre
								<UnfoldMoreIcon style={{ float: "right" }} />
							</TableCell>
							<TableCell>
								Capability
								<UnfoldMoreIcon style={{ float: "right" }} />
							</TableCell>
							<TableCell>
								Title
								<UnfoldMoreIcon style={{ float: "right" }} />
							</TableCell>
							<TableCell>
								Skills
								<UnfoldMoreIcon style={{ float: "right" }} />
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{usersToDisplay &&
							usersToDisplay
								.slice(0, numOfUsersToDisplay)
								.map((user: any) => (
									<TableRow
										key={user.name}
										sx={{
											"td, th": {
												border: 1,
												borderColor: "#E5E5E5",
											},
										}}
									>
										<TableCell>{user.name}</TableCell>
										<TableCell>
											{user.build_centre}
										</TableCell>
										<TableCell>{user.capability}</TableCell>
										<TableCell>{user.title}</TableCell>

										{/* Temporary */}
										{/* This will need to be updated when the advanced feature is done, and the user is able to search based on more than one skill. */}
										{user.skills.map((skill: any) => {
											return (
												skill.name ===
													selectedSkill && (
													<TableCell
														key={`${user.name}_${skill.name}`}
														align="left"
													>
														<span className="skill-cell-background">
															{skill.name}
														</span>
													</TableCell>
												)
											);
										})}

										<TableCell className="view-button-cell">
											<button
												className="view-button"
												onClick={navigateToUserProfileOnClick(
													user.id
												)}
											>
												View
											</button>
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>

			<div className="no-results-container">
				{!usersToDisplay && (
					<>
						<img
							src={NoResultsImage}
							alt="No results"
							className="no-results-image"
						/>
						<h3>Nothing to see here, yet...</h3>
						<p>Search for a skill to see results</p>
					</>
				)}
				{usersToDisplay?.length === 0 && (
					<>
						<img
							src={NoResultsImage}
							alt="No results"
							className="no-results-image"
						/>
						<h3>No results found</h3>
						<p>
							Looks like there are no builders that match that
							search criteria, reset the filters and try again.
						</p>
						<button className="cyan-button" onClick={resetFilters}>
							Reset filters
						</button>
					</>
				)}
			</div>

			{!hideShowMoreButton && (
				<Grid>
					<div className="show-more">
						<button
							className="white-button"
							onClick={showMoreButton}
						>
							Show more
						</button>
					</div>
				</Grid>
			)}
		</div>
	);
};

export default BrowseResults;
