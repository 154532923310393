import LocationOnIcon from "@mui/icons-material/LocationOn";
import PlaceholderAvatar from "assets/images/placeholder_avatar.svg";

import User from "types/user";

import "./profile-info.css";


const ProfileInfo = ({ profile }: { profile: User | null }) => {
	return (
		<div className="profile-info-container">
			<img
				src={PlaceholderAvatar}
				alt="avatar"
				className="profile-avatar"
			/>

			{profile ? (
				<div className="profile-info">
					<h2 className="profile-info-data">{profile.name}</h2>
					<h5 className="profile-info-data">{profile.title}</h5>
					<h5 className="profile-info-data">{profile.capability}</h5>
					<h5 className="profile-info-data profile-info-data-with-icon">
						<LocationOnIcon
							style={{ position: "relative", top: "6px" }}
						/>
						{profile.build_centre}
					</h5>
				</div>
			) : (
				<>Profile not found</>
			)}
		</div>
	);
};

export default ProfileInfo;
