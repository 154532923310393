import { AccountInfo } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "auth-config";
import { useCallback } from "react";
import { UseAuthenticationHook } from "./types";

const useMsalAuthentication = (): UseAuthenticationHook => {
	const { instance, accounts } = useMsal();
	const account: AccountInfo | null = accounts.length ? accounts[0] : null;
	
	const getAccessToken = useCallback(async (): Promise<string | void> => {
		let response;
		if(account){
			const request = {
				...loginRequest,
				account,
			};
			try {
				response = await instance.acquireTokenSilent(request);
				return response.accessToken;
			} catch {
				response = await instance.acquireTokenPopup(request);
				return response.accessToken;
			}
		}

		throw new Error("Missing account");
	}, [account])

	const logout = useCallback(async () => {
		instance?.logoutRedirect().catch((e: any) => {
			console.error(e);
		});
	}, [instance]);

	return {
		getAccessToken,
		isAuthenticated: account != null,
		name: account?.name,
		id: account?.username,
		logout
	}
}

export default useMsalAuthentication;
