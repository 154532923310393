import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { OptionType } from "types/option-type";
import { capabilities } from "constants/capabilities";
import Skill from "types/skill";
import { Autocomplete, Box, Button, Chip, FormControl, Stack, TextField, Typography } from "@mui/material";

type SelectSkillsProps = {
	className: string;
	accountSkills: any[];
	skills: string[];
	addSkills: (skills: Skill[]) => void;
	removeSkill: (index: number) => void;
	updateStep: (step: string) => void;
}



export const SelectSkills = ({ className, skills, accountSkills, updateStep, removeSkill, addSkills }: SelectSkillsProps) => {
	const [selectedCapability, setSelectedCapability] = useState("");
	const [selectedSkills, setSelectedSkills] = useState<OptionType[]>([]);

	const capabilitiesOptions = useMemo(() => {
		return capabilities.map((c) => ({ label: c, value: c }));
	}, []);

	const isValid = useMemo(() => {
		if (!accountSkills?.length && !selectedSkills?.length) {
			return false;
		}

		return Boolean(selectedSkills?.length && selectedCapability);

	}, [accountSkills, selectedSkills, selectedCapability]);

	const skillOptions = useMemo(() => {
		return skills?.map(s => ({ label: s, value: s }));
	}, [skills]);

	const onCapabilityChange = (_event: SyntheticEvent<Element, Event>, option: OptionType | null) => {
		setSelectedCapability(option?.value || "");
	};

	const onSkillsChange = (_event: SyntheticEvent<Element, Event>, value: OptionType[]) => {
		setSelectedSkills(value);
	};

	const onContinueClick = useCallback(() => {
		if (isValid) {
			const skillsToAdd = selectedSkills.map(({ value }) => ({ name: value, capability: selectedCapability, proficiency: "" }));
			addSkills(skillsToAdd);
			updateStep("skills-details");
		}
	}, [isValid, selectedSkills]);

	const onSkillDeleted = (index: number) => () => {
		removeSkill(index);
	};

	return (
		<Box maxWidth="sm" ml="auto" mr="auto" className={className}>	
			<h3>Select Skills</h3>
			<p>Browse from the list of skills and add them to your profile</p>
			<Stack direction="column" spacing={4} justifyContent="center" alignItems="center">
				<FormControl fullWidth>
					<Typography variant="subtitle1">Select a Capability</Typography>
					<Autocomplete
						data-testid="select-capability"
						options={capabilitiesOptions}
						filterSelectedOptions
						onChange={onCapabilityChange}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={`ex. ${capabilities[0]}`}
							/>
						)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<Typography variant="subtitle1">Select Skills to Add</Typography>
					<Autocomplete
						data-testid="select-skills"
						options={skillOptions}
						multiple
						filterSelectedOptions
						disabled={!skills.length}
						onChange={onSkillsChange}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={!skills.length ? "Loading Skills..." : "Try Searching for Skills..."}
							/>
						)}
					/>
				</FormControl>

				{accountSkills?.length > 0 &&
					<FormControl fullWidth>
						<Typography variant="subtitle1">My Skills</Typography>
						<Stack spacing={1} direction="row">
							{
								accountSkills?.map(({ name }: Skill, index: number) => (
									<Chip color="primary" label={name} key={index} onDelete={onSkillDeleted(index)} />
								))
							}
						</Stack>
					</FormControl>
				}

				<Button
					variant="contained"
					color="secondary"
					disabled={!isValid}
					onClick={onContinueClick}>
					Continue
				</Button>
			</Stack>
		</Box>
	);
};