import beacon from "assets/images/_beacon.png";
import { NavLink } from "react-router-dom";
import "./welcome.css";

const WelcomePage = () => {
	return (
		<div className="welcome-container">
			<img src={beacon} alt="_beacon" className="beacon-img"></img>
			<h1>
        Welcome to <span className="beacon_title">_b</span>eacon
			</h1>
			<p className="info-text">
        You&apos;re one step closer to having access to all the experts on our
        team at Slalom Build. But before, let&apos;s get you started by setting
        up your account.
			</p>
			<NavLink to="/skills/add">
				<button className="cyan-button">Start now</button>
			</NavLink>
		</div>
	);
};

export default WelcomePage;
