import "./skill-box.css";
import ClearIcon from "@mui/icons-material/Clear";

const SkillBox = (props: any) => {
	const deleteSkill = () => {
		props.deleteSkill(props.index);
	};

	return (
		<div className="skill-box" aria-label="div-skillbox">
			<label aria-label="label-skillbox">{props.skill.name}&nbsp;</label>
			{props.isEditable ? (
				<button onClick={deleteSkill} aria-label="clear-icon">
					<ClearIcon />
				</button>
			) : (
				<span aria-label="span-skillbox"> ({props.skill.proficiency})</span>
			)}
		</div>
	);
};

export default SkillBox;
