import { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Modal, Tab, Tabs } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ClearIcon from "@mui/icons-material/Clear";
import { Pages } from "constants/pages";
import logo from "assets/images/app_logo.png";
import { useAuthentication } from "hooks/auth/useAuthentication";
import { useAuthenticatedProfile } from "hooks/useAuthenticatedProfile";

import "./navbar.css";

const Navbar = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { logout } = useAuthentication();
	const { isNewUser } = useAuthenticatedProfile();

	const [anchorElNav, setAnchorElNav] = useState(null);
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleLogout = () => {
		logout().then(()=>{
			console.log("logged out");
		});
	};

	const handleOpenNavMenu = (event: any) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const navigateToPage = (page: string) => {
		navigate(page);
		handleCloseNavMenu();
	};

	const handleChange = (tabLink: string) => {
		navigate(tabLink);
	};

	return (
		<header>
			<AppBar position="static" className="navbar">
				<Container maxWidth="xl">
					<Toolbar disableGutters className="customizeToolbar">
						{window.innerWidth > 700 ? (
							<NavLink to="/">
								<img id="app_logo" src={logo} alt="logo" className="logo" />
							</NavLink>
						) : (
							<></>
						)}
						<Box
							sx={{
								flexGrow: 1,
								display: { xs: "flex", md: "none", lg: "none" },
							}}
						>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
								open={Boolean(anchorElNav)}
								className="MenuColor"
								onClose={handleCloseNavMenu}
								sx={{
									display: {
										xs: "block",
										md: "none",
									},
								}}
							>
								{Pages.map((page) => (
									<MenuItem
										key={page.title}
										id={page.id}
										onClick={() => navigateToPage(page.route)}
									>
										<Typography textAlign="center">{page.title}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
						<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
							<Tabs
								sx={{ marginLeft: "auto" }}
								value={
									Pages.findIndex((p) => p.route === pathname) === -1
										? false
										: pathname
								}
								onChange={(event, value) => handleChange(value)}
							>
								{isNewUser ? (
									<Tab
										id={"tab_welcome"}
										className="custom-tab"
										key="Welcome"
										label="Welcome"
										data-testid="welcome-tab"
										value={pathname}
									/>
								) : (
									Pages.filter((page) => {
										return page.id !== "welcome" && page.id !== "add-skills";
									}).map((page) => (
										<Tab
											data-testid="custom-test"
											id={`tab_${page.id}`}
											className="custom-tab"
											key={page.title}
											label={page.title}
											value={page.route}
										/>
									))
								)}
							</Tabs>
						</Box>

						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Logout">
								<LogoutIcon
									onClick={handleOpen}
									sx={{ p: 0 }}
									color="primary"
								></LogoutIcon>
							</Tooltip>
							<Modal
								open={open}
								onClose={handleClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Box className="logout-modal">
									<div>
										<Typography
											id="modal-modal-title"
											variant="h6"
											fontFamily="Slalom Sans"
											fontWeight="700"
											fontSize="16px"
										>
											Are you sure you want to log out?
											<ClearIcon
												style={{
													float: "right",
													fontSize: "1.5rem",
													cursor: "pointer",
												}}
												onClick={handleClose}
											/>
										</Typography>
									</div>
									<div>
										<div style={{ float: "left", marginTop: "26px" }}>
											<button
												className="white-button"
												onClick={handleClose}
												data-testid="tests-button"
											>
												Cancel
											</button>
										</div>
										<div style={{ float: "right", marginTop: "26px" }}>
											<button className="cyan-button" onClick={handleLogout}>
												Log out
											</button>
										</div>
									</div>
								</Box>
							</Modal>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</header>
	);
};
export default Navbar;
