import { useEffect, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import "./skill-level.css";

const SkillLevel = (props: any) => {
	const [proficiency, setProficiency] = useState<string>("");
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const onValueChange = (e: any) => {
		setProficiency(e.target.value);
		props.updateSkill(e.target.value, props.index);
	};

	const handleDelete = (index: number) => {
		props.removeSkill(index);
		handleClose();
	};

	useEffect(() => {
		setProficiency(props.skill.proficiency);
	}, [props.skill.proficiency]);

	return (
		<div className="proficiency-row">
			<label className="skill-name"> {props.skill.name}</label>
			<div className="proficiency-values">
				<div className="proficiency-value">
					<input
						type="radio"
						value="basic"
						checked={proficiency === "basic"}
						onChange={onValueChange}
					/>
				</div>
				<div className="proficiency-value">
					<input
						type="radio"
						value="intermediate"
						checked={proficiency === "intermediate"}
						onChange={onValueChange}
					/>
				</div>
				<div className="proficiency-value">
					<input
						type="radio"
						value="proficient"
						checked={proficiency === "proficient"}
						onChange={onValueChange}
					/>
				</div>
				<div className="proficiency-value">
					<input
						type="radio"
						value="expert"
						checked={proficiency === "expert"}
						onChange={onValueChange}
					/>
				</div>
			</div>
			<div>
				{props.mode === "edit" && (
					<>
						<div onClick={handleOpen}>
							<button className="build-button delete">
								<RemoveCircleOutlineIcon sx={{ fontSize: "1.2rem" }} />
                &nbsp; Delete
							</button>
						</div>
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box className="delete-modal">
								<ClearIcon
									style={{
										float: "right",
										fontSize: "1.5rem",
										cursor: "pointer",
									}}
									onClick={handleClose}
								/>
								<Typography
									id="modal-modal-title"
									variant="h6"
									component="h2"
									style={{
										fontFamily: "Slalom Sans",
										fontWeight: "700",
										float: "left",
										width: "450px",
									}}
								>
                  You are about to delete {props.skill.name} from your profile
								</Typography>
								<Typography
									id="modal-modal-description"
									sx={{ mt: 10, textAlign: "left", fontFamily: "Slalom Sans" }}
								>
                  Are you sure you want to delete this skill? This change is
                  irreversible but you will be able to add it again at any time.
								</Typography>
								<div style={{ float: "left", marginTop: "26px" }}>
									<button className="white-button" onClick={handleClose}>
                    Cancel
									</button>
								</div>
								<div style={{ float: "right", marginTop: "26px" }}>
									<button
										className="cyan-button"
										onClick={() => handleDelete(props.index)}
									>
                    Delete
									</button>
								</div>
							</Box>
						</Modal>
					</>
				)}
			</div>
		</div>
	);
};
export default SkillLevel;
