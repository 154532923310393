/* eslint-disable @typescript-eslint/no-unused-vars */
import Select, { components } from "react-select";
import { useEffect, useMemo, useState } from "react";
import Skill from "types/skill";
import { OptionType } from "types/option-type";
import useAuthenticatedFetch from "hooks/auth/useAuthenticatedFetch";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Box, Divider } from "@mui/material";
import "./browse-search.css";
import { capabilities } from "constants/capabilities";

const BrowseSearch = (props: any) => {
	const [skillOptions, setSkillOptions] = useState<Array<OptionType>>([]);
	const [selectedSkill, setSelectedSkill] = useState<any>();
	const [isFilterDisabled, setIsFilterDisabled] = useState<boolean>(true);

	const jobTitleOptions= [
		{ value: "", label: "All" },
		{ value: "Engineer", label: "Engineer" },
		{ value: "Analyst", label: "Analyst" },
		{ value: "Designer", label: "Designer" },
		{ value: "Senior Engineer", label: "Senior Engineer" },
		{ value: "Senior Analyst", label: "Senior Analyst" },
		{ value: "Senior Designer", label: "Senior Designer" },
		{ value: "Architect", label: "Architect" },
		{ value: "Senior Architect", label: "Senior Architect" },
		{ value: "Principal", label: "Principal" },
		{ value: "Director", label: "Director" },
		{ value: "Senior Director", label: "Senior Director" },
		{ value: "Managing Director", label: "Managing Director" },
	];

	const buildCentreOptions = [
		{ value: "", label: "All" },
		{ value: "Manchester", label: "Manchester" },
		{ value: "London", label: "London" },
	];

	const capabilityOptions = useMemo(()=> {
		const _capabilityOptions = [{
			value: "",
			label: "All",
		}];

		capabilities.forEach(c => _capabilityOptions.push({label: c, value: c}));

		return _capabilityOptions;
	}, []);

	const authenticatedFetch = useAuthenticatedFetch();

	const DropdownIndicator = (props: any) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<SearchIcon style={{ color: "#09091C" }}></SearchIcon>
				</components.DropdownIndicator>
			)
		);
	};

	const getSkills = async () => {
		const url = `${process.env.REACT_APP_SKILLS_API_URL}`;
		const response = await authenticatedFetch(url);
		const options = response.map((s: string) => {
			return { label: s, value: s };
		});
		setSkillOptions(options);
	};

	useEffect(() => {
		getSkills();
	}, []);

	const onSkillChange = (skill: OptionType) => {
		if (!skill) {
			setIsFilterDisabled(true);
			setSelectedSkill({ label: "", value: "" });
			props.onBuildCentreChange({ value: "", label: "All" });
			// props.onProficiencyChange({ value: "", label: "All" });
			props.onCapabilityChange({ value: "", label: "All" });
			props.onJobTitleChange({ value: "", label: "All" });
		}
		setSelectedSkill(skill);
	};

	const searchSkills = () => {
		isFilterDisabled && setIsFilterDisabled(false);

		if (selectedSkill) {
			props.returnSelectedSkill(selectedSkill.value);
		}
		props.onBuildCentreChange({ value: "", label: "All" });
		// props.onProficiencyChange({ value: "", label: "All" });
		props.onCapabilityChange({ value: "", label: "All" });
		props.onJobTitleChange({ value: "", label: "All" });
	};

	return (
		<>
			<Grid className="browse-header">
				<div>
					<h3>Browse</h3>
				</div>
			</Grid>
			<div className="browse-field">
				<Box sx={{ p: 2, border: "1px solid #E8E8E8" }}>
					<Grid container spacing={0.5} className="search-box">
						<Grid item xs={8.8}>
							<Select
								components={
									!selectedSkill
										? { DropdownIndicator, IndicatorSeparator: () => null }
										: { DropdownIndicator: () => null, IndicatorSeparator: () => null }
								}
								options={skillOptions}
								value={selectedSkill}
								onChange={onSkillChange}
								placeholder="Search for a skill"
								classNamePrefix="browse-select"
								isClearable={true}
								isSearchable={true}
							/>
						</Grid>
						<Grid item xs={3}>
							<div>
								<button className="cyan-button" onClick={searchSkills}>
									Search
								</button>
							</div>
						</Grid>
					</Grid>
				</Box>
			</div>
			<Grid className="filter-container" container>
				<Grid item xs={3.9}>
					<div className="filter-label">
						<label>Job Title (optional)</label>
					</div>
					<Select
						components={{ IndicatorSeparator: () => null }}
						options={jobTitleOptions}
						value={props.jobTitleFieldValue}
						onChange={props.onJobTitleChange}
						placeholder="Select Job Title"
						classNamePrefix="browse-select"
						isSearchable={true}
						isMulti={false}
						isDisabled={isFilterDisabled}
						closeMenuOnSelect={true}
					/>
				</Grid>
				<Grid item xs={3.9}>
					<div className="filter-label">
						<label>Build Centre (optional)</label>
					</div>
					<Select
						components={{ IndicatorSeparator: () => null }}
						options={buildCentreOptions}
						value={props.buildCentreFieldValue}
						onChange={props.onBuildCentreChange}
						placeholder="Select Build Centre"
						classNamePrefix="browse-select"
						isSearchable={true}
						isMulti={false}
						isDisabled={isFilterDisabled}
						closeMenuOnSelect={false}
					/>
				</Grid>
				<Grid item xs={3.9}>
					<div className="filter-label">
						<label>Capability (optional)</label>
					</div>
					<Select
						components={{ IndicatorSeparator: () => null }}
						options={capabilityOptions}
						value={props.capabilityFieldValue}
						onChange={props.onCapabilityChange}
						placeholder="Select Capability"
						classNamePrefix="browse-select"
						isSearchable={true}
						isMulti={false}
						isDisabled={isFilterDisabled}
						closeMenuOnSelect={false}
					/>
				</Grid>
			</Grid>
			<Divider sx={{ marginTop: 3 }} component="div" role="presentation" />
		</>
	);
};

export default BrowseSearch;
