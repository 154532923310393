import { createContext, useContext, ReactNode } from "react";
import User from "types/user";
import { useAuthentication } from "./auth/useAuthentication";
import { useProfile } from "./useProfile";

type AuthenticatedProfile = {
	id?: string;
	name?: string;
	isAuthenticated: boolean;
	isNewUser: boolean;
	profile: User | null;
	loading: boolean;
	updateProfile: (user: Partial<User>) => Promise<User | null>;
};

const AuthenticatedProfileContext = createContext<AuthenticatedProfile>({
	id: "",
	name: "",
	isAuthenticated: false,
	isNewUser: false,
	profile: null,
	loading: true,
	updateProfile: () => Promise.resolve(null),
});

export const useAuthenticatedProfile = () => useContext(AuthenticatedProfileContext);

export const AuthenticatedProfileProvider = ({children}: {children: ReactNode}) => {
	const { id, name, isAuthenticated } = useAuthentication();

	const { updateProfile, profile, loading } = useProfile(id);

	const contextValue = {
		id, 
		name, 
		isAuthenticated,
		isNewUser: !(profile?.skills?.length),
		profile,
		loading,
		updateProfile,
	}

	return (
		<AuthenticatedProfileContext.Provider value={contextValue}>
			{children}
		</AuthenticatedProfileContext.Provider>
	)
}
