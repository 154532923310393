import "./skills-added.css";
import beacon from "assets/images/_beacon.png";
import { NavLink } from "react-router-dom";

export const SkillsAdded = (props: any) => {
	return (
		<div className={`skills-added-container ${props.className}`}>
			<img src={beacon} alt="_beacon" className="skills-beacon-img"></img>
			<h3>Skills added!</h3>
			<p className="info-text">
				Your profile has been updated. You will be able to update them or add
				more at any time.
			</p>
			<NavLink className="no-link" to="/">
				<button className="cyan-button">View profile</button>
			</NavLink>
			<br />
			<br />
			<button
				className="white-button"
				onClick={() => props.updateStep("add-skills")}
			>
				Add more skills
			</button>
		</div>
	);
};