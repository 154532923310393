import SkillsPage from "pages/skills/skills";
import WelcomePage from "pages/welcome/welcome";
import BrowsePage from "pages/browse/browse";
import ProfilesPage from "pages/profiles/profiles";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthenticatedProfile } from "hooks/useAuthenticatedProfile";

const DefaultRoute = () => {
	const navigate = useNavigate();
	const {id, loading, isNewUser} = useAuthenticatedProfile();

	useEffect(() => {
		if (!loading){
			if (id && !isNewUser) {
				navigate(`/profiles/${id}`);
			} else {
				navigate("/welcome");
			}
		}
	}, [navigate, loading, id, isNewUser]);

	return <></>;
};

export const MainRoutes = () => (
	<Routes>
		<Route path="/" element={<DefaultRoute/>} />
		<Route path="/welcome" element={<WelcomePage />} />
		<Route path="/skills/:mode" element={<SkillsPage />} />
		<Route path="/browse" element={<BrowsePage />} />
		<Route path="/profiles/:userID" element={<ProfilesPage />} />
	</Routes>
);