import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { loginRequest, msalConfig } from "auth-config";
import App from "./App";

import "./index.css";
import { AuthenticatedProfileProvider } from "hooks/useAuthenticatedProfile";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
	.handleRedirectPromise()
	.then((authResult: any) => {
		// Check if user signed in
		if (!authResult || !authResult.account) {
			// redirect anonymous user to login page
			msalInstance.loginRedirect(loginRequest);
		}
	})
	.catch((err) => {
		console.log(err);
	});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<MsalProvider instance={msalInstance}>
		<AuthenticatedProfileProvider>
			<App />
		</AuthenticatedProfileProvider>
	</MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
