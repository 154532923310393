import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SelectSkillsLevel } from "components/skills/select-skills-level/select-skills-level";
import { SelectSkills } from "components/skills/select-skills/select-skills";
import { SkillsAdded } from "components/skills/skills-added/skills-added";
import { useAuthenticatedFetch } from "hooks/auth/useAuthenticatedFetch";
import Skill from "types/skill";
import { useAuthenticatedProfile } from "hooks/useAuthenticatedProfile";

import "./skills.css";
import { Container } from "@mui/material";

const SkillsPage = () => {
	const { mode } = useParams();

	const [currentStep, setCurrentStep] = useState("add-skills");
	const [skills, setSkills] = useState<string[]>([]);
	const [userSkills, setUserSkills] = useState<Array<Skill>>([]);

	const authenticatedFetch = useAuthenticatedFetch();
	const navigate = useNavigate();

	const { name, id, profile, isNewUser, updateProfile } = useAuthenticatedProfile();

	useEffect(() => {
		if (mode === "edit") {
			setCurrentStep("skills-details");
		} else if (mode === "add") {
			setCurrentStep("add-skills");
		}
	}, [mode]);

	useEffect(() => {
		setUserSkills(profile?.skills || []);
	}, [profile]);

	const addSkills = (skillsToAdd: Skill[]) => {
		setUserSkills([...userSkills, ...skillsToAdd]);
	};

	const removeSkill = (index: number) => {
		const userSkillsCopy = [...userSkills];
		userSkillsCopy.splice(index, 1);
		setUserSkills(userSkillsCopy);
	};

	const updateSkill = (value: string, index: number) => {
		const userSkillsCopy = [...userSkills];
		userSkillsCopy[index] = {
			...userSkillsCopy[index],
			proficiency: value,
		};
		setUserSkills(userSkillsCopy);
	};

	const updateStep = (step: string) => {
		setCurrentStep(step);
	};

	const getSkills = useCallback(async () => {
		const url = `${process.env.REACT_APP_SKILLS_API_URL}`;
		const _skills = await authenticatedFetch(url);
		setSkills(_skills);
	}, [authenticatedFetch]);

	const saveSkills = async () => {
		const userProfile = {
			id,
			name,
			build_centre: "Unknown",
			capability: "Unknown",
			title: "Unknown",
			skills: userSkills,
		};

		await updateProfile(userProfile);

		if (!isNewUser && mode === "edit") {
			navigate(`/profiles/${id}`, {
				state: {
					alerts: [
						{
							severity: "success",
							message:
								"Your skills have been updated!",
						},
					],
				},
			});
		}
		updateStep("skills-updated");
	};

	useEffect(() => {
		getSkills();
	}, [getSkills]);

	return (
		<Container fixed className="skills-container">
			<SelectSkills
				className={currentStep === "add-skills" ? "" : "hidden"}
				updateStep={updateStep}
				skills={skills}
				accountSkills={userSkills}
				addSkills={addSkills}
				removeSkill={removeSkill}
			/>
			<SelectSkillsLevel
				className={currentStep === "skills-details" ? "" : "hidden"}
				saveSkills={saveSkills}
				accountSkills={userSkills}
				updateSkill={updateSkill}
				removeSkill={removeSkill}
				mode={mode}
			/>
			<SkillsAdded
				className={currentStep === "skills-updated" ? "" : "hidden"}
				updateStep={updateStep}
			/>
		</Container>
	);
};

export default SkillsPage;
