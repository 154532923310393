import { createTheme, ThemeProvider } from "@mui/material/styles";
import { HashRouter as Router } from "react-router-dom";

import Navbar from "components/navbar/navbar";
import { MainRoutes } from "routes/main-routes";

import { useAuthenticatedProfile } from "hooks/useAuthenticatedProfile";
import { LinearProgress } from "@mui/material";

import "./App.css";
import React from "react";

const theme = createTheme({
	palette: {
		primary: {
			main: "#3B444E",
			contrastText: "#ffffff",
		},
		secondary: {
			main: "#1be1f2",
			contrastText: "#000000",
		},
		background: {
			default: "#ffffff",
			paper: "#ffffff",

		},
		text: {
			primary: "#3B444E",
			secondary: "#3B444E"
		},
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: "0px"
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: "0px"
				}
			}	
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "0px",
					fontWeight: 700,
					fontSize: "16px",
					fontFamily: "Slalom Sans",
					height: "50px",
					minWidth: "175px",
				}
			}	
		}
	}

});

function App() {
	const { loading, isAuthenticated } = useAuthenticatedProfile();

	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				{isAuthenticated
					?
					<Router>
						<Navbar />
						<div className="page-container">
							{
								loading &&
								<LinearProgress color="secondary" />
							}
							<MainRoutes />
						</div>
					</Router>
					:
					<p>You are not signed in! Please sign in.</p>
				}
			</ThemeProvider>
		</div>
	);
}

export default React.memo(App);
