import SkillBox from "components/skill-box/skill-box";
import { chain } from "lodash";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Skill from "types/skill";
import "./profile-skills.css";

const ProfileSkills = ({ skills, isLoggedInProfile }: { skills: Skill[], isLoggedInProfile: boolean }) => {
	const [groupedSkills, setGroupedSkills] = useState<any>();

	useEffect(() => {
		const processedSkills = chain(skills)
			.groupBy("capability")
			.map((value, key) => ({ capability: key, skills: value }))
			.value();
		setGroupedSkills(processedSkills);
	}, [skills]);

	return (
		<div className="profile-skills-container">
			<div className="skills-title">
				<h3>Skills</h3>
				{isLoggedInProfile &&
					<div className="skills-title-buttons-container">
						<NavLink className="no-link" to="/skills/edit">
							<button className="white-button">Edit Skills</button>
						</NavLink>
						<NavLink className="no-link" to="/skills/add">
							<button className="cyan-button">Add Skills</button>
						</NavLink>
					</div>
				}
			</div>
			<div>
				{groupedSkills ? (
					groupedSkills.map((gs: any) => {
						return (
							<div id={`${gs.capability}`} key={gs.capability}>
								<h5 className="skills-capability-title">{gs.capability}</h5>
								<div className="skills-capability-container">
									{gs.skills.map((s: any) => {
										return <SkillBox skill={s} key={s}></SkillBox>;
									})}
								</div>
							</div>
						);
					})
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default ProfileSkills;
