import { useCallback, useEffect, useState } from "react";
import User from "types/user";
import { useAuthenticatedFetch } from "./auth/useAuthenticatedFetch";


export const useProfile = (id?: string) => {
	const [profile, setProfile] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);

	const authenticatedFetch = useAuthenticatedFetch();

	const getProfile = useCallback(async (profileId?: string): Promise<User | null> => {
		if (!profileId) {
			return null;
		}

		const url = `${process.env.REACT_APP_PROFILE_API_URL}/${profileId}`;

		const response = await authenticatedFetch(url);

		if (Array.isArray(response) && response?.length) {
			return response[0];
		}

		return null;

	}, [authenticatedFetch]);

	const refreshProfile = useCallback(async () => {
		setLoading(true);

		const _profile: User | null = await getProfile(id);

		if (_profile) {
			setProfile(_profile);
		}

		setLoading(false);

		return _profile;
	}, [id]);

	const updateProfile = useCallback(async (updates: Partial<User>) => {
		setLoading(true);

		let _profile: User | null = null;

		if (id) {
			_profile = await authenticatedFetch(`${process.env.REACT_APP_PROFILE_API_URL}`, "POST", {
				...profile,
				...updates,
				id,
			}, false);

			if (_profile) {
				setProfile(_profile);
			}
		}

		setLoading(false);

		return _profile;
	}, [id]);

	useEffect(() => {
		refreshProfile();
	}, [refreshProfile]);

	return {
		updateProfile,
		profile,
		loading
	}
}



