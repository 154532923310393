import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import "./alerts.css";

const Alerts = (props: any) => {
	const ALERT_COLOR_MAP: any = {
		success: "#E7FF80",
		info: "#8CF0F9",
		error: "#FF5F6F",
		warning: "#FFA25F",
	};

	return (
		<Stack justifyContent="center" alignItems="center" spacing={2} mt={2}>
			{props.alerts.map((alert: any, index: number) => {
				return (
					<Collapse in={alert.open} key={index}>
						<Alert
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => props.closeAlert(index)}
									sx={{ width: 14, height: 14, padding: 2 }}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							className="alert"
							sx={{ backgroundColor: ALERT_COLOR_MAP[alert.severity] }}
							severity={alert.severity}
						>
							{alert.message}
						</Alert>
					</Collapse>
				);
			})}
		</Stack>
	);
};

export default Alerts;
